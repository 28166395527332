import React from "react"
import "../styles.css"
import peterImage from "../assets/Peter_Jacoby_sw.jpg"
import Navbar from "../components/Navbar"
import about from "../content/about.json"

export default function Home() {
  return (
  <div>
    <Navbar></Navbar>
        <div class="content">
          <div class="contentbox">
            <div class="peter" id="header">Peter Jacoby <br/>freelance senior postproduction <br/>and creative producer</div>
            <div class="content-index">
              
            <div class="imgbox">
              <img class="img" src={peterImage} />
              <div class="contact">
                <a href="tel:+491728627879">+49 172 862 78 79</a>
                <a href="mailto:post@peterjacoby.de"> post@peterjacoby.de</a>
                <a href="https://www.linkedin.com/in/peter-jacoby-post/" target="_blank">LinkedIn</a>
                <a href="https://www.xing.com/profile/Peter_Jacoby" target="_blank">Xing</a>
                <a href="https://vimeo.com/peterjacoby" target="_blank">Vimeo</a>
                <a href="https://www.instagram.com/peter_jacoby_post/" target="_blank">Instagram</a>
              </div>
            </div>

            <div class="text">
              {about.items.map(about => (      
                <div class="about">{about.text}</div>))}<a href="/impressum" class="impressum-link" target="_blank">imprint / privacy policy</a>
 
            </div>

        </div>
        </div>
        </div>
  </div>
)}